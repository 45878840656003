export enum ExtraAmountParagraphType {
    AmendedFinancials = 1,
    SegregatedAssets = 2,
    ContraventionReport = 3,
    TitleSearchNsw = 4,
    TitleSearchOther = 5,
    RelatedUnitTrust = 6,
    LimitedResourceBorrowing = 7,
    AsicSearch = 8,
    ClassApiCharge = 10
}