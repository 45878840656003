import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {IColumn, SelectionMode, Text} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Card, DataTable, ItemDeletedStatus} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {RulesetsDataTableActions} from './RulesetsDataTableActions';
import {useRulesetsStore} from '../RulesetsPage';
import {cloneDeep} from 'lodash';

export const RulesetsDataTable: FunctionComponent = observer(() => {
    const {rulesetsLoading, rulesets, rulesetsFilters} = useRulesetsStore();
    const {formatMessage} = useIntl();

    const columns: IColumn[] = [
        {
            key: 'id',
            name: formatMessage({id: 'id'}),
            fieldName: 'id',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => (
                <Text variant='medium' styles={{root: {fontWeight: 500}}}>
                    {item.id}
                </Text>
            ),
        },
        {
            key: 'workflowName',
            name: formatMessage({id: 'rulesetName'}),
            fieldName: 'workflowName',
            minWidth: 150
        },
        {
            key: 'status',
            name: formatMessage({id: 'status'}),
            minWidth: 80,
            maxWidth: 100,
            onRender: (item) => <ItemDeletedStatus item={item}/>,
        },
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => <RulesetsDataTableActions ruleset={item}/>,
        },
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                initialColumns={columns}
                shimmerLines={rulesetsFilters.pageSize}
                items={cloneDeep(rulesets)}
                selectionMode={SelectionMode.none}
                enableShimmer={rulesetsLoading}
                disableDragDrop={true}
            />
        </Card>
    );
});
