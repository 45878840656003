
import {CustomProcedureRowRender} from "../../CustomProcedureTemplates";
import {IDetailsRowProps, Stack} from "@fluentui/react";
import React, { createElement, FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import {ControlledDatePicker, ControlledTextField, ITableColumn, SanitizedText} from "../../../../../../../components";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../../constants";
import {useJobContext} from "../../../../../JobPortalLayoutPage";
import {useUpdateItemAnswer} from "../../../../../hooks/answers";
import {ColumnInfo} from "../../../../../enums";
import {useIntl} from "react-intl";
import {GeneratedAnswer} from "../../../../GeneratedAnswer";
import {AnswerControlType} from "../../../../../../../enums";
import { useWorkContext } from "../../../../../../../providers";
import { useTabContext } from "../../../../../JobPortalPage";

interface IMemberNameProcedureProps {
    procedure: any,
    onUpdateDob?: (newDob?: string) => void;
    onUpdateAddress?: (newAddress?: string) => void;
}

interface IMemberNameForm {
    dob?: string;
    address?: string;
}

const MemberNameProcedureNameTemplate: FunctionComponent<IMemberNameProcedureProps> = ({
                                                                                           procedure,
                                                                                           onUpdateDob,
                                                                                           onUpdateAddress,
                                                                                       }: IMemberNameProcedureProps) => {
    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const { isTabEnabled} = useTabContext();
    
    const {update, isUpdating} = useUpdateItemAnswer();

    const {control} = useForm<IMemberNameForm>({
        ...DefaultFormSettings,
        defaultValues: {
            address: procedure.answerText2,
            dob: procedure.answerText3,
        }
    });
    
    const handleAddressBlur = useCallback(() => {
        const newValue = control._formValues['address'];
        
        if(newValue === procedure.answerText2)
        {
            return;
        }

        update({
            jobId: jobId,
            tableType: procedure.tableType,
            itemId: procedure.id,
            text: newValue,
            columnInfo: ColumnInfo.Text2,
        }, {
            onSuccess: () => {
                onUpdateAddress?.(newValue)
            }
        })
    }, [])

    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        console.debug("[ANSWER::MEMBER_NAME::DISABLE]", isTabEnabled, isUpdating);
        setDisabled(!isTabEnabled || isUpdating)
    }, [isTabEnabled, isUpdating]);

    return (
        <Stack tokens={{childrenGap: 8}}
               styles={{root: {width: '100%', paddingLeft: (procedure.level || 0) * 16}}}>
            <Stack.Item>
                <SanitizedText data={`${formatMessage({id: 'nameOfMember'})} ${procedure.reference.match(/\d+/g)[0]}:`}/>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal tokens={{childrenGap: 8}} styles={{root: {width: '100%' }}}>
                    <Stack.Item style={{ flexGrow: 1 }}>
                        <Stack tokens={{childrenGap: 10}}>
                            <Stack.Item>
                                <SanitizedText data={formatMessage({id: 'dob'}) + ':'} format={'Bold'} />
                            </Stack.Item>
                            <Stack.Item>
                                <GeneratedAnswer
                                    value={procedure.answerText3}
                                    answerControlType={AnswerControlType.Calendar}
                                    columnInfo={ColumnInfo.Text3}
                                    itemId={procedure.id}
                                    tableType={procedure.tableType}
                                    onUpdate={(newValue) => onUpdateDob?.(newValue)}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item style={{ flexGrow: 5 }}>
                        <ControlledTextField onBlur={handleAddressBlur} name='address' label={formatMessage({id: 'address'}) + ':'}
                                             control={control}
                                             disabled={disabled}/>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

export const memberNameProcedureRowRender: CustomProcedureRowRender = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null, rows?: any[], onRowItemUpdate?: (newItem: any) => void) => {
    if (!props || !defaultRender) {
        //technically these may be undefined...
        return null;
    }

    const auditProcedureColumn = (props?.columns ?? []).find(x => x.key === 'auditProcedure');
    if (auditProcedureColumn) {
        // custom auditProcedure column render
        const memberNameAuditProcedureColumn: ITableColumn = {
            ...auditProcedureColumn,
            key: 'auditProcedure_memberName',
            onRender: (item, index, column) => {
                return index != undefined && createElement(MemberNameProcedureNameTemplate, {
                    procedure: item,
                    onUpdateDob: (newDob?: string) => {
                        item.answerText3 = newDob;
                        onRowItemUpdate?.(item);
                    },
                    onUpdateAddress: (newAddress?: string) => {
                        item.answerText2 = newAddress;
                        onRowItemUpdate?.(item);
                    }
                })
            }
        }

        const index = props.columns.indexOf(auditProcedureColumn);

        const columns = [...props.columns];
        columns[index] = {...memberNameAuditProcedureColumn}

        props.columns = [...columns]
    }

    return defaultRender(props);
}