import React, {FunctionComponent} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import {useGetJobByGuid} from 'hooks/useGetJobByGuid';
import {JobContext} from './JobPortalLayoutPage';
import {IconButton, Stack, Text, useTheme} from "@fluentui/react";
import {Loading} from "../../components";
import {mergeStyleSets} from "@fluentui/react/lib/Styling";
import {useGetAllAnswerOptions} from "./hooks/answers";


export const JobPortalExternalLayoutPage: FunctionComponent = () => {
    const theme = useTheme();
    const {guid} = useParams();

    const {dataJobs, isJobLoading, refetchJob, isJobRefetching} = useGetJobByGuid({guid});
    const {response: optionsResponse, isLoading: isOptionsLoading} = useGetAllAnswerOptions();

    return (
        <JobContext.Provider value={{
            jobGuid: guid || '',
            jobYear: dataJobs?.data.year,
            jobId: dataJobs?.data?.id,
            fundId: dataJobs?.data?.fund?.id,
            clientGuid: dataJobs?.data?.client.guid,
            isJobAuditFinalised: !!dataJobs?.data?.auditFinalised,
            job: dataJobs?.data,
            answerOptions: optionsResponse?.data,
            isAnswerOptionsLoading: isOptionsLoading,
            refetch: refetchJob,
            jobTabs: [],
            suggestedTestingInfo: undefined
        }}>
            <Stack styles={{
                root: {
                    background: theme.palette.neutralLighter,
                    overflow: 'auto',
                    height: '100%'
                }
            }}>
                {isJobLoading && !dataJobs?.data?.id && (
                    <Stack horizontalAlign='center' verticalAlign='center' styles={{root: {height: '85vh',}}}>
                        <Loading/>
                    </Stack>)}
                {!isJobLoading && (<Outlet/>)}
            </Stack>
        </JobContext.Provider>
    );
};


type JobPortalExternalPageContentProps = {
    title: string;
    content: React.ReactElement,
    controls?: React.ReactElement
    isLoading?: boolean;
    handleRefresh?: Function,
    topStackStyles?: any
}

export const JobPortalExternalPageContent: FunctionComponent<JobPortalExternalPageContentProps> = ({
                                                                                                       title,
                                                                                                       content,
                                                                                                       controls,
                                                                                                       isLoading,
                                                                                                       handleRefresh,
                                                                                                       ...props
                                                                                                   }: JobPortalExternalPageContentProps) => {
    const theme = useTheme();

    const classNames = mergeStyleSets({
        header: {
            height: 50,
            paddingLeft: 16,
            paddingRight: 16,
            overflow: 'hidden',
            flexGrow: 1,
            borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`,
            background: 'rgba(184, 184, 184, 0.2)',
            boxShadow: theme.effects.elevation4,
        },
        content: {
            padding: 16,
            background: theme.palette.white,
        }
    })

    const onHandleRefreshClick = () => {
        handleRefresh?.()
    }

    const defaultStyles = {root: {margin: '16px 16px 20px 16px', boxShadow: theme.effects.elevation4}};

    return (
        <Stack styles={props.topStackStyles ?? defaultStyles}>
            {isLoading
                ? <Stack.Item styles={{root: {padding: 16}}}>
                    <Loading/>
                </Stack.Item>
                : <>
                    <Stack.Item className={classNames.header}>
                        <Stack verticalAlign={'center'} horizontal horizontalAlign={'space-between'}
                               styles={{root: {height: '100%'}}}>
                            <Stack.Item>
                                <Text theme={theme.schemes?.default}>{title}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack>
                                    <Stack.Item>
                                        {handleRefresh && <IconButton iconProps={{iconName: 'refresh'}}
                                                                      onClick={onHandleRefreshClick}
                                        />}
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>

                    </Stack.Item>
                    <Stack.Item className={classNames.content}>
                        <Stack tokens={{childrenGap: 16}}>
                            <Stack.Item>
                                {content}
                            </Stack.Item>
                            <Stack.Item align={'end'}>
                                {controls ?? <></>}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </>
            }
        </Stack>

    )
}