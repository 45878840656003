import {Outlet, useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {IAdministrationContext, IAdministrationListItem} from 'interfaces';
import * as React from "react";
import { useGetUserClients } from './hooks/useGetUserClients';
import { useGetClientFunds } from './hooks/useGetClientFunds';

export const initialContext: IAdministrationContext = {
    filters: {
        page: 1,
        pageSize: 25,
        search: '',
    },
    isLoading: false,
    selectedClient: null,
    selectedFund: null,
    targetClient: null,
    targetFund: null,
    setFilters: () => {
    },
    setLoading: () => {
    },
    setSelectedClient: () => {
    },
    setSelectedFund: () => {
    },
    setTargetClient: () => {
    },
    setTargetFund: () => {
    },
};

const AdministrationContext = React.createContext(initialContext);

export const useAdministrationContext = () => {
    return React.useContext(AdministrationContext);
};

export const JobsLayoutPage = () => {
    const {formatMessage} = useIntl();
    const location = useLocation();
    const navigation = useNavigate();

    const [selectedClient, setSelectedClient] = React.useState<IAdministrationListItem | null>(null);
    const [selectedFund, setSelectedFund] = React.useState<IAdministrationListItem | null>(null);
    const [filters, setFilters] = React.useState<IAdministrationContext['filters']>(initialContext.filters);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [targetClient, setTargetClient] = React.useState<IAdministrationListItem | null>(null);
    const [targetFund, setTargetFund] = React.useState<IAdministrationListItem | null>(null);

    const { clientsData } = useGetUserClients({});
    const { fundsData } = useGetClientFunds({clientGuid: selectedClient?.guid});

    React.useEffect(() => {
        setFilters(initialContext.filters);
        if (location.pathname === '/administration') {
            navigation(RouterPaths.administration.jobs.root);
        }
    }, [location.pathname, navigation]);

    React.useEffect(() => {
        const pathnameParts = location.pathname.split('/');
        if (pathnameParts.length > 3 && pathnameParts[1] === 'administration' && !selectedClient && clientsData?.data.some(x => x.guid === pathnameParts[3])) {
            setSelectedClient(clientsData.data.find(x => x.guid === pathnameParts[3])!);
        }
    }, [location.pathname, clientsData?.data]);

    React.useEffect(() => {
        const pathnameParts = location.pathname.split('/');
        if (pathnameParts.length > 4 && pathnameParts[1] === 'administration' && !selectedFund && fundsData?.data.some(x => x.guid === pathnameParts[4])) {
            setSelectedFund(fundsData.data.find(x => x.guid === pathnameParts[4])!);
        }
    }, [location.pathname, fundsData?.data]);

    return (
        <AdministrationContext.Provider value={{
            filters,
            isLoading,
            selectedClient,
            selectedFund,
            targetClient,
            targetFund,
            setFilters,
            setLoading,
            setSelectedClient,
            setSelectedFund,
            setTargetClient,
            setTargetFund,
        }}>
            <PageWithBreadcrumb
                breadcrumb={{
                    id: 'clients',
                    key: RouterPaths.administration.jobs.root,
                    text: formatMessage({id: 'clients'}),
                }}>
                <Outlet context={[selectedClient, setSelectedClient]}/>
            </PageWithBreadcrumb>
        </AdministrationContext.Provider>
            
    );
};

export function useJobsOutletContext() {
    return useOutletContext<[IAdministrationListItem | null, React.Dispatch<React.SetStateAction<IAdministrationListItem>>]>();
}