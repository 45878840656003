import React, { createElement, FunctionComponent, ReactElement, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DefaultButton, DialogType, IDetailsRowProps, MessageBarType, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import { CustomProcedureRowRender } from '../../CustomProcedureTemplates';
import { ControlledCheckbox, DialogMessage, ITableColumn } from '../../../../../../../components';
import { getFlatProcedures } from '../../../../../../../utils';
import { useScheduleOrderSearch } from '../hooks';
import { useJobContext } from '../../../../../JobPortalLayoutPage';
import { DropdownAnswer } from '../../../answers';
import { NotificationType, PortalRole, useSignalR, useWorkContext } from '../../../../../../../providers';
import { useNotifications } from '../../../../../../../components/notifications';
import { useProcedureContext } from '../../../../ProceduresContent';
import { useForm } from 'react-hook-form';
import { useGetClientAuditInfoByGuid } from '../../../../../hooks';
import { PortalRoleGuard, TenantGuard } from '../../../../../../../guards';

interface IOrderSearchProcedureProps {
    procedure: any;
    disabled: boolean;
    abn?: string;
}

const OrderSearchProcedureNameTemplate: FunctionComponent<IOrderSearchProcedureProps> = ({
    procedure,
    disabled = true,
    abn,
}: IOrderSearchProcedureProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { refresh } = useProcedureContext();
    const { useSignalREffect } = useSignalR();
    const { showNotification } = useNotifications();
    const { jobId, clientGuid } = useJobContext();

    const { isLoading: isGettingAuditInfo, refetch: getClientAuditInfo } = useGetClientAuditInfoByGuid(clientGuid, false);

    const { schedule, isScheduling } = useScheduleOrderSearch();

    const { control, watch, setValue } = useForm<{ override: boolean }>({
        ...DefaultButton,
        defaultValues: { override: false },
    });

    const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
    const watchField = watch();

    const orderSearchDialogProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: <FormattedMessage id={'warning'} />,
        closeButtonAriaLabel: 'Close',
    };

    const handleDismissAlert = async () => {
        setShowWarningAlert(false);
        setValue('override', false);

        if (watchField.override) {
            await scheduleSearch(false);
        }
    };

    useSignalREffect(
        'orderSearch_completed',
        (notification: NotificationType) => {
            const binaryString = window.atob(notification.content.report.content);
            const binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            let FileSaver = require('file-saver');
            let blob = new Blob([bytes], { type: notification.content.report.type });
            FileSaver.saveAs(blob, notification.content.report.fileName);
            showNotification({
                name: formatMessage({ id: 'orderSearch' }),
                description: formatMessage({ id: 'orderSearch_notification_completed' }),
                type: MessageBarType.success,
            });
            refresh?.();
        },
        [showNotification]
    );

    useSignalREffect(
        'orderSearch_error',
        (notification: NotificationType) => {
            showNotification({
                name: formatMessage({ id: 'orderSearch' }),
                description: notification.content.error,
                type: MessageBarType.error,
            });
        },
        [showNotification]
    );

    const scheduleSearch = async (verify: boolean) => {
        if (verify) {
            const valid = await verifyOrder();

            if (!valid) {
                setShowWarningAlert(true);

                return;
            }
        }

        schedule({
            jobId: jobId,
            itemId: procedure.id,
            tableType: procedure.tableType,
            abn: abn,
            verify: false,
            name: procedure.id.toString(),
        });
    };
    const verifyOrder = async () => {
        const auditInfoResp = await getClientAuditInfo();

        return !((!abn && !auditInfoResp.data!.titleSearchConduct) || (!!abn && !auditInfoResp.data!.asicSearchConduct));
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign='center'>
            <Stack.Item
                styles={{
                    root: {
                        maxWidth: '100%',
                        paddingLeft: (procedure.level || 0) * 16,
                        paddingTop: 8,
                        paddingBottom: 8,
                    },
                }}>
                <PrimaryButton disabled={disabled || isScheduling || isGettingAuditInfo} onClick={() => scheduleSearch(true)}>
                    {isGettingAuditInfo && <FormattedMessage id={'jobPortal_button_verifying'} />}
                    {isScheduling && <FormattedMessage id={'jobPortal_button_scheduling'} />}
                    {!isGettingAuditInfo && !isScheduling && <FormattedMessage id={'jobPortal_button_orderSearch'} />}
                </PrimaryButton>
                <DialogMessage
                    hidden={!showWarningAlert}
                    onClick={handleDismissAlert}
                    showCancel={false}
                    dialogContentProps={orderSearchDialogProps}
                    onDismis={handleDismissAlert}>
                    <Stack grow tokens={{ childrenGap: 16 }}>
                        <Text>{formatMessage({ id: 'orderSearch_label_warning' })}</Text>
                        <TenantGuard behavior='hide' types={['black']}>
                            <PortalRoleGuard
                                roles={[PortalRole.CPAdministrator, PortalRole.Administrator]}
                                behavior='hide'
                                includeSuperAdministrator>
                                <ControlledCheckbox
                                    name={'override'}
                                    control={control}
                                    label={formatMessage({ id: 'orderSearch_label_override' })}
                                />
                            </PortalRoleGuard>
                        </TenantGuard>
                        <TenantGuard behavior='hide' types={['white']}>
                            <PortalRoleGuard roles={[PortalRole.Administrator]} behavior='hide' includeSuperAdministrator>
                                <ControlledCheckbox
                                    name={'override'}
                                    control={control}
                                    label={formatMessage({ id: 'orderSearch_label_override' })}
                                />
                            </PortalRoleGuard>
                        </TenantGuard>
                    </Stack>
                </DialogMessage>
            </Stack.Item>
        </Stack>
    );
};

const OrderSearchProcedureResultTemplate: FunctionComponent<IOrderSearchProcedureProps> = ({
    procedure,
    disabled,
}: IOrderSearchProcedureProps) => {
    const dropdownOptions = useMemo(
        () => [
            {
                key: procedure.answerText,
                text: procedure.answerText,
            },
        ],
        [procedure.answerText]
    );

    return <DropdownAnswer value={procedure.answerText} disabled={disabled} options={dropdownOptions} />;
};

export const orderSearchProcedureRowRender: CustomProcedureRowRender = (
    props?: IDetailsRowProps,
    defaultRender?: (props?: IDetailsRowProps) => ReactElement | null,
    rows?: any[]
) => {
    if (!props || !defaultRender) {
        //technically these may be undefined...
        return null;
    }

    const { isCorporateTrusteeType, isAbnSet, abn, isDatesDiffer } = ((
        items?: any
    ): {
        isCorporateTrusteeType: boolean;
        isAbnSet: boolean;
        abn?: string;
        isDatesDiffer: boolean;
    } => {
        const flatted = getFlatProcedures(items ?? [], []);

        const isCorporateTrusteeType = flatted.find((x) => x.reference === 'Trustee Type')?.answerText === 'Corporate';
        const abn = flatted.find((x) => x.reference === 'Corporate Trustee Name')?.answerText2;
        const isAbnSet = !!abn;

        const date1 = flatted.find((x) => x.reference === 'Date of ASIC Company Statement')?.answerText;
        const date2 = flatted.find((x) => x.reference === 'Date of last changed recorded on ASIC free search')?.answerText;
        const isDatesDiffer = date1 && date2 && date1 < date2;

        return { isCorporateTrusteeType, isAbnSet, abn, isDatesDiffer };
    })(rows);

    const disabled = !(isCorporateTrusteeType && isAbnSet && isDatesDiffer);

    const auditProcedureColumn = (props?.columns ?? []).find((x) => x.key === 'auditProcedure');
    if (auditProcedureColumn) {
        // custom auditProcedure column render
        const orderSearchAuditProcedureColumn: ITableColumn = {
            ...auditProcedureColumn,
            key: 'auditProcedure_orderSearch',
            onRender: (item, index, column) => {
                return createElement(OrderSearchProcedureNameTemplate, { procedure: item, disabled: disabled, abn: abn });
            },
        };

        const index = props.columns.indexOf(auditProcedureColumn);

        const columns = [...props.columns];
        columns[index] = { ...orderSearchAuditProcedureColumn };

        props.columns = [...columns];
    }

    const resultColumn = (props?.columns ?? []).find((x) => x.key === 'result');
    if (resultColumn) {
        // custom result column render
        const orderSearchResultColumn: ITableColumn = {
            ...resultColumn,
            key: 'result_orderSearch',
            onRender: (item, index, column) => {
                return createElement(OrderSearchProcedureResultTemplate, { procedure: item, disabled: true });
            },
        };

        const index = props.columns.indexOf(resultColumn);

        const columns = [...props.columns];
        columns[index] = { ...orderSearchResultColumn };

        props.columns = [...columns];
    }

    return defaultRender(props);
};
