import {CustomProcedureRowRender} from "../../CustomProcedureTemplates";
import {IDetailsRowProps, IDropdownOption, Stack} from "@fluentui/react";
import { createElement, FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import {ITableColumn} from "../../../../../../../components";
import {useJobContext} from "../../../../../JobPortalLayoutPage";
import {ColumnInfo} from "../../../../../enums";
import {GeneratedAnswer} from "../../../../GeneratedAnswer";
import {AnswerControlType} from "../../../../../../../enums";
import {useIntl} from "react-intl";
import {useUpdateItemAnswer} from "../../../../../hooks/answers";
import { useTabContext } from "../../../../../JobPortalPage";

interface ITrusteeTypeProcedureProps {
    procedure: any,
    onUpdateType?: (newType: string) => void
}

const TrusteeTypeProcedureResultTemplate: FunctionComponent<ITrusteeTypeProcedureProps> = ({
                                                                                               procedure,
                                                                                               onUpdateType
                                                                                           }: ITrusteeTypeProcedureProps) => {
    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const { isTabEnabled} = useTabContext();
    const {update, isUpdating} = useUpdateItemAnswer();

    const options: IDropdownOption[] = [
        {key: '', text: ''},
        {key: 'Individual', text: formatMessage({id: 'individual'})},
        {key: 'Corporate', text: formatMessage({id: 'corporate'})}
    ]

    const handleOnChange = useCallback((value: string) => {
        update({
            jobId: jobId,
            tableType: procedure.tableType,
            itemId: procedure.id,
            text: value,
            columnInfo: ColumnInfo.Text
        }, {
            onSuccess: () => {
                onUpdateType?.(value)
            }
        })
    }, [])

    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        console.debug("[ANSWER::TRUSTEE_TYPE::DISABLE]", isTabEnabled, isUpdating);
        setDisabled(!isTabEnabled || isUpdating)
    }, [isUpdating, isTabEnabled]);

    return (
        <Stack verticalAlign='center' grow>
            <GeneratedAnswer columnInfo={ColumnInfo.Text} itemId={procedure.id} tableType={procedure.tableType}
                             options={options} onChange={handleOnChange}
                             value={procedure.answerText} auto={procedure.isAnswerDisabled} disabled={disabled}
                             answerControlType={AnswerControlType.Dropdown}/>
        </Stack>
    )
}

export const trusteeTypeProcedureRowRender: CustomProcedureRowRender = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null, rows?: any[], onRowItemUpdate?: (newItem: any) => void) => {
    if (!props || !defaultRender) {
        //technically these may be undefined...
        return null;
    }

    const resultColumn = (props?.columns ?? []).find(x => x.key === 'result');
    if (resultColumn) {
        // custom result column render
        const trusteeTypeAuditProcedureColumn: ITableColumn = {
            ...resultColumn,
            key: 'result_trusteeType',
            onRender: (item, index, column) => {
                return createElement(TrusteeTypeProcedureResultTemplate, {
                    procedure: item,
                    onUpdateType: (newType: string) => {
                        item.answerText = newType
                        onRowItemUpdate?.(item);
                    }
                })
            }
        }

        const index = props.columns.indexOf(resultColumn);

        const columns = [...props.columns];
        columns[index] = {...trusteeTypeAuditProcedureColumn}

        props.columns = [...columns]
    }

    return defaultRender(props);
}