import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateCreateDataType = {
    jobId: number;
    abn?: string;
    verify: boolean;
    name: string;
    itemId: number;
    tableType: number;
}

function useScheduleOrderSearch() {
    const context = useContext(Context);
    const {
        mutate: schedule,
        mutateAsync: scheduleAsync,
        isLoading: isScheduling
    } = useMutation((data: MutateCreateDataType) => axios.post(`${context?.config?.orderSearchUrl}/api/1.0/jobs/${data.jobId}/orderSearch/schedule`, data))

    return {schedule, scheduleAsync, isScheduling};
}

export {useScheduleOrderSearch};
