import React, { FunctionComponent, useEffect } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {MainLayoutPage} from 'pages/Layout';
import {RequireAuth} from './RequiredAuth';
import {CreditNotesLayoutPage, InvoicePortalLayoutPage, OtherClientsLayoutPage} from 'pages/InvoicePortal';
import {
    AuditReadyConnectionsLayoutPage,
    AuditReadyConnectionsPage,
    EmailSettingsLayoutPage,
    EmailSettingsPage,
    EmailTemplatesLayoutPage,
    EmailTemplatesPage,
    GeneralSettingsLayoutPage,
    GeneralSettingsPage,
    MaterialityCalculatorSettingsLayoutPage,
    MaterialityCalculatorSettingsPage,
    PortalSettingsLayoutPage,
    RiskMatrixSettingsLayoutPage,
    RiskMatrixSettingsPage,
    TestingAndMethodologySettingsLayoutPage,
    TestingAndMethodologySettingsPage,
} from 'pages/ClientPortal/PortalSettings';
import {AdminLayoutPage} from 'pages/AdminPortal';
import {
    RulesetLayoutPage,
    RulesetNewRuleLayoutPage,
    RulesetPage,
    RulesetRuleLayoutPage,
    RulesetRulePage,
    RulesetRulesLayoutPage,
    RulesetsLayoutPage,
    RulesetsPage,
} from 'pages/AdminPortal/Rulesets';
import {UserManagementLayoutPage, UserManagementPage} from 'pages/ClientPortal/UserManagement';
import {RouterPaths} from './RouterPaths';
import {CustomizationLayoutPage, CustomizationPage} from 'pages/ClientPortal/Customization';
import {NotFoundPage} from 'pages/shared';
import {
    FundsListLayoutPage,
    FundsListPage,
    JobListLayoutPage,
    JobListPage,
    JobsLayoutPage,
    JobsPage,
} from 'pages/ClientPortal/Administration/jobs';
import {
    ClientsWorkflowLayoutPage,
    ClientsWorkflowPage,
    DashboardWorkflowLayoutPage,
    DashboardWorkflowPage,
    EmployeesWorkflowLayoutPage,
    EmployeesWorkflowPage,
    FundsWorkflowLayoutPage,
    FundsWorkflowPage,
    JobsWorkflowLayoutPage,
    JobsWorkflowPage,
    ReportsWorkflowLayoutPage,
    ReportsWorkflowPage,
    TimesheetWorkflowLayoutPage,
    TimesheetWorkflowPage,
    TrusteesWorkflowLayoutPage,
    TrusteesWorkflowPage,
    WorkflowLayoutPage,
} from 'pages/WorkflowPortal';
import {createSignalRContext} from 'react-signalr';
import {JobPortalExternalLayoutPage, JobPortalLayoutPage, JobPortalPage} from 'pages/JobPortal';
import {ImportReportPage} from 'pages/ImportReport';
import {
    AdministrationDashboardsLayoutPage,
    AdministrationDashboardsPage,
    AdministrationLayoutPage
} from "pages/ClientPortal/Administration";
import { PortalRole, useAuthContext, useTenantInfo } from "../providers";
import {CreditNotesPageTemplate} from "../pages/InvoicePortal/components/CreditNotes";
import {InvoicesLayoutPage} from "../pages/InvoicePortal";
import {InvoicesPageTemplate} from "../pages/InvoicePortal/components/Invoices";
import {OtherClientsPageTemplate} from "../pages/InvoicePortal/components/OtherClients";
import {CalculatorExternalTemplate} from "../pages/JobPortal/components/Calculators/CalculatorExternalTemplate";
import {SectionExternalTemplate} from "../pages/JobPortal/components";
import { OAuthResultPage } from "../pages/shared/OAuthPage";
import { LibraryLayoutPage, LibraryPage } from 'pages/ClientPortal/Library';
import { RequireRoles } from "./RequireRoles";

export const AppRoutes: FunctionComponent = () => {
    const {tenant} = useTenantInfo();
    const {user} = useAuthContext();

    useEffect(() => {
        console.debug("[ROUTES::VALUES]", tenant, user);
    }, [tenant, user]);

    return (
        <Routes>
            
            {/*invoice portal*/}
            <Route
                path={RouterPaths.invoices.root()}
                element={
                    <RequireAuth>
                        <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser, PortalRole.Administrator, PortalRole.PowerUser]}>
                            <InvoicePortalLayoutPage/>
                        </RequireRoles>
                    </RequireAuth>
                }>
                <Route path={RouterPaths.invoices.invoices} element={<InvoicesLayoutPage/>}>
                    <Route path={RouterPaths.invoices.invoices} element={<InvoicesPageTemplate/>}/>
                </Route>
                <Route path={RouterPaths.invoices.creditNotes} element={<CreditNotesLayoutPage/>}>
                    <Route path={RouterPaths.invoices.creditNotes} element={<CreditNotesPageTemplate/>}/>
                </Route>
                <Route path={RouterPaths.invoices.otherClients} element={<OtherClientsLayoutPage/>}>
                    <Route path={RouterPaths.invoices.otherClients} element={<OtherClientsPageTemplate/>}/>
                </Route>
            </Route>

            {/*client portal*/}
            <Route
                path='/'
                element={
                    <RequireAuth>
                        <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser, PortalRole.CPUser, PortalRole.CPAdministrator]}>
                            <MainLayoutPage/>
                        </RequireRoles>
                    </RequireAuth>
                }>
                <Route path='*' element={<NotFoundPage/>}/>
                <Route path='/' element={<Navigate
                    to={tenant?.IsWhiteLabel ? '/workflow-portal/dashboard' : (user?.accessToDashboard ? '/administration/dashboards' : '/administration/jobs')}
                    replace/>}/>
                <Route path={RouterPaths.portalSettings.root} element={
                    <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser, PortalRole.CPAdministrator]}>
                        <PortalSettingsLayoutPage/>
                    </RequireRoles>
                }>
                    <Route path={RouterPaths.portalSettings.auditReadyConnections}
                           element={<AuditReadyConnectionsLayoutPage/>}>
                        <Route path={RouterPaths.portalSettings.auditReadyConnections}
                               element={<AuditReadyConnectionsPage/>}/>
                    </Route>
                    <Route path={RouterPaths.portalSettings.generalSettings} element={<GeneralSettingsLayoutPage/>}>
                        <Route path={RouterPaths.portalSettings.generalSettings} element={<GeneralSettingsPage/>}/>
                    </Route>

                    <Route path={RouterPaths.portalSettings.riskMatrixSettings}
                           element={<RiskMatrixSettingsLayoutPage/>}>
                        <Route path={RouterPaths.portalSettings.riskMatrixSettings}
                               element={<RiskMatrixSettingsPage/>}/>
                    </Route>

                    <Route
                        path={RouterPaths.portalSettings.materialityCalculatorSettings}
                        element={<MaterialityCalculatorSettingsLayoutPage/>}>
                        <Route
                            path={RouterPaths.portalSettings.materialityCalculatorSettings}
                            element={<MaterialityCalculatorSettingsPage/>}
                        />
                    </Route>

                    <Route
                        path={RouterPaths.portalSettings.testingAndMethodologySettings}
                        element={<TestingAndMethodologySettingsLayoutPage/>}>
                        <Route
                            path={RouterPaths.portalSettings.testingAndMethodologySettings}
                            element={<TestingAndMethodologySettingsPage/>}
                        />
                    </Route>

                    <Route path={RouterPaths.portalSettings.emailSettings} element={<EmailSettingsLayoutPage />}>
                        <Route path={RouterPaths.portalSettings.emailSettings} element={<EmailSettingsPage />} />
                    </Route>

                    <Route path={RouterPaths.portalSettings.emailTemplates} element={<EmailTemplatesLayoutPage/>}>
                        <Route path={RouterPaths.portalSettings.emailTemplates} element={<EmailTemplatesPage/>}/>
                    </Route>
                </Route>
                <Route path={RouterPaths.userManagement} element={
                    <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser, PortalRole.CPAdministrator]}>
                        <UserManagementLayoutPage/>
                    </RequireRoles>
                }>
                    <Route path={RouterPaths.userManagement} element={<UserManagementPage/>}/>
                </Route>

                <Route path={RouterPaths.library} element={
                    <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser, PortalRole.CPAdministrator]}>
                        <LibraryLayoutPage/>
                    </RequireRoles>
                }>
                    <Route path={RouterPaths.library} element={<LibraryPage/>}/>
                </Route>

                <Route path={RouterPaths.administration.root} element={<AdministrationLayoutPage/>}>

                    <Route path={RouterPaths.administration.dashboards}
                           element={<AdministrationDashboardsLayoutPage/>}>
                        <Route path={RouterPaths.administration.dashboards}
                               element={<AdministrationDashboardsPage/>}/>
                    </Route>
                    <Route path={RouterPaths.administration.jobs.root} element={<JobsLayoutPage/>}>
                        <Route path={RouterPaths.administration.jobs.root} element={<JobsPage/>}/>
                        <Route path={RouterPaths.administration.jobs.funds.root()} element={<FundsListLayoutPage/>}>
                            <Route path={RouterPaths.administration.jobs.funds.root()} element={<FundsListPage/>}/>
                            <Route path={RouterPaths.administration.jobs.funds.jobList.root()}
                                   element={<JobListLayoutPage/>}>
                                <Route path={RouterPaths.administration.jobs.funds.jobList.root()}
                                       element={<JobListPage/>}/>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path={RouterPaths.customization} element={
                    <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser, PortalRole.CPAdministrator]}>
                        <CustomizationLayoutPage/>
                    </RequireRoles>
                }>
                    <Route path={RouterPaths.customization} element={<CustomizationPage/>}/>
                </Route>

                <Route path={RouterPaths.workflow.root} element={<WorkflowLayoutPage/>}>
                    <Route path={RouterPaths.workflow.dashboard} element={<DashboardWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.dashboard} element={<DashboardWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.clients} element={<ClientsWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.clients} element={<ClientsWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.trustees} element={<TrusteesWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.trustees} element={<TrusteesWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.funds} element={<FundsWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.funds} element={<FundsWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.employees} element={<EmployeesWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.employees} element={<EmployeesWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.jobs} element={<JobsWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.jobs} element={<JobsWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.reports} element={<ReportsWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.reports} element={<ReportsWorkflowPage/>}/>
                    </Route>
                    <Route path={RouterPaths.workflow.timesheet} element={<TimesheetWorkflowLayoutPage/>}>
                        <Route path={RouterPaths.workflow.timesheet} element={<TimesheetWorkflowPage/>}/>
                    </Route>
                </Route>
                
                <Route path={RouterPaths.oauth.index} element={<OAuthResultPage/>}>
                    
                </Route>
            </Route>

            {/*admin portal*/}
            <Route
                path={RouterPaths.admin.root}
                element={
                    <RequireAuth>
                        <RequireRoles accessFor={[PortalRole.SuperAdministrator, PortalRole.SuperUser]}>
                            <AdminLayoutPage/>
                        </RequireRoles>
                    </RequireAuth>
                }>
                {
                    <Route path={RouterPaths.admin.rulesets.root} element={<RulesetsLayoutPage/>}>
                        <Route path={RouterPaths.admin.rulesets.root} element={<RulesetsPage/>}/>

                        <Route path={RouterPaths.admin.rulesets.ruleset.root()} element={<RulesetLayoutPage/>}>
                            <Route path={RouterPaths.admin.rulesets.ruleset.root()} element={<RulesetPage/>}/>

                            <Route path={RouterPaths.admin.rulesets.ruleset.rules.root()}
                                   element={<RulesetRulesLayoutPage/>}>
                                <Route path={RouterPaths.admin.rulesets.ruleset.rules.new()}
                                       element={<RulesetNewRuleLayoutPage/>}>
                                    <Route path={RouterPaths.admin.rulesets.ruleset.rules.new()}
                                           element={<RulesetRulePage/>}/>
                                </Route>
                                <Route path={RouterPaths.admin.rulesets.ruleset.rules.rule()}
                                       element={<RulesetRuleLayoutPage/>}>
                                    <Route path={RouterPaths.admin.rulesets.ruleset.rules.rule()}
                                           element={<RulesetRulePage/>}/>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                }
            </Route>
            
            {/*job portal*/}
            <Route
                path={RouterPaths.job.root()}
                element={
                    <RequireAuth>
                        <RequireRoles denyFor={[PortalRole.CPUser]}>
                            <JobPortalLayoutPage/>
                        </RequireRoles>
                    </RequireAuth>
                }>
                <Route path={RouterPaths.job.tab()} element={<JobPortalPage/>}/>
            </Route>
            <Route
                path={RouterPaths.importReport.root()}
                element={
                    <RequireAuth>
                        <RequireRoles denyFor={[PortalRole.CPUser]}>
                            <ImportReportPage/>
                        </RequireRoles>
                    </RequireAuth>
                }>
            </Route>
            <Route
                path={RouterPaths.job.external.root()}
                element={
                    <RequireAuth>
                        <RequireRoles denyFor={[PortalRole.CPUser]}>
                            <JobPortalExternalLayoutPage/>
                        </RequireRoles>
                    </RequireAuth>
                }>
                <Route path={RouterPaths.job.external.calculator()} element={<CalculatorExternalTemplate/>}/>
                <Route path={RouterPaths.job.external.section()} element={<SectionExternalTemplate/>}/>
            </Route>
        </Routes>
    )
};
