import {IPaginationFilter} from "../../../interfaces";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {DefaultPaginationPageSize} from "../../../../../constants/paginationSettings";
import {InvoiceStatusType} from "../enums";
import { IInvoice } from "./IInvoice";

export interface IInvoicesFilter extends IPaginationFilter {
    status: InvoiceStatusType
    fundName?: string;
    year?: number;
    invoiceNumber?: number
}

export interface IInvoicesContext {
    filter: IInvoicesFilter;
    setFilter: (filter: IInvoicesFilter) => void;
    selection: { 
        items: IInvoice[],
        setItems: Dispatch<SetStateAction<IInvoice[]>>,
        clear: () => void;
    }
}

export const InvoicesContext = createContext<IInvoicesContext>({
    filter: {
        pageNumber: 1,
        pageSize: DefaultPaginationPageSize,
        status: InvoiceStatusType.Open,
    },
    setFilter: () => {},
    selection: {
        items: [],
        setItems: () => {},
        clear: () => {}
    }
});

export const useInvoicesContext = () => useContext<IInvoicesContext>(InvoicesContext);