import React, {FunctionComponent, useMemo} from "react";
import {DetailsListLayoutMode, IColumn, SelectionMode, Text} from "@fluentui/react";
import {useIntl} from "react-intl";
import {DataTable} from "../../../../../components";
import {IInvoiceExtraAmount} from "../interfaces";
import {ExtraAmountParagraphType} from "../enums";

interface IInvoiceExtraAmountDataTableProps {
    items: IInvoiceExtraAmount[];
}

export const InvoiceExtraAmountDataTable: FunctionComponent<IInvoiceExtraAmountDataTableProps> = ({ items }: IInvoiceExtraAmountDataTableProps) => {
    const {formatMessage, formatNumber} = useIntl();

    const columns: IColumn[] = useMemo(() => [
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 100,
            maxWidth: 300,
            isMultiline: true,
            onRender: (item: IInvoiceExtraAmount) => (
                <Text>{formatNumber(item.amount ?? 0, { style: 'currency', currency: 'USD' })}</Text>
            ),
        },
        {
            key: 'paragraphName',
            name: formatMessage({ id: 'invoiceParagraph' }),
            fieldName: 'paragraphName',
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IInvoiceExtraAmount) => (
                <Text>{formatMessage({id: `extraAmountParagraph.${ExtraAmountParagraphType[item.paragraph]}`})}</Text>
            ),
        },
    ], [formatMessage]);
    
    return (
        <>
            <DataTable
                initialColumns={columns}
                items={items ?? []}
                selectionMode={SelectionMode.none}
                setKey='none'
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                hideIfEmpty={false}
            />
        </>
    )
}