import moment from 'moment-timezone';
import {Text} from "@fluentui/react";
import { FunctionComponent } from "react";
import { globalTimeZone } from "../utils";

type TimeZoneDateTimeTextProps = {
	dateTime?: string | Date;
	
	timezome?: string;
	format?: TimeZoneDateTimeFormat;
}

enum TimeZoneDateTimeFormat {
	DateOnlyFormat = 'DD/MM/yyyy'
}

export const TimeZoneDateTimeText: FunctionComponent<TimeZoneDateTimeTextProps> = ({ dateTime, timezome = globalTimeZone, format = TimeZoneDateTimeFormat.DateOnlyFormat, ...props }: TimeZoneDateTimeTextProps) => {
	return (
		<>
			<Text>{moment(dateTime).tz(globalTimeZone).format(format)}</Text>
		</>
	);
};