import {DefaultButton, mergeStyleSets, NeutralColors, SharedColors, Stack, useTheme} from '@fluentui/react';
import {
    forwardRef,
    FunctionComponent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {useUpdateItemAnswer} from "../../../hooks/answers";
import {TableType} from "../../../../../enums/TableType";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {ColumnInfo} from "../../../enums";
import {ThirdOptionProps, useReportContext, useSectionContext} from '../..';
import {useProcedureContext} from '../../ProceduresContent';
import {isNegativeAnswer, isPositiveAnswer} from './consts';
import {useTabContext} from "../../../JobPortalPage";

export type WorkDoneSwitchAnswerProps = {
    value?: string;
    itemId: number;
    tableType: TableType;
    columnInfo: ColumnInfo;
    reverse?: boolean;
    auto?: boolean;
    disabled?: boolean;
    disabledPartialy?: 'valid' | 'invalid' | 'third';
    iconTypes?: 'icons' | 'character';
    onUpdate?: (answer: string | null) => void;
    onChange?: (answer: string | null) => void;
    thirdOption?: ThirdOptionProps;
    ignoreSectionAnswerUpdating?: boolean;
    ignoreAnswerUpdating?: boolean;
}
export const WorkDoneSwitchAnswer = forwardRef(({
                                                    iconTypes = 'icons',
                                                    thirdOption,
                                                    ...props
                                                }: WorkDoneSwitchAnswerProps, ref) => {
    const theme = useTheme();

    const {jobId, answerOptions, isAnswerOptionsLoading} = useJobContext();
    const {isPositiveSectionAnswer, updateSectionAnswer} = useSectionContext();
    const {isTabEnabled} = useTabContext();

    const procedureContext = useProcedureContext();
    const reportContext = useReportContext();

    const classNames = mergeStyleSets({
        valid: [
            {
                minWidth: 36,
                width: 36,
                padding: '0 10px',
                borderColor: NeutralColors.gray60,
                color: theme.semanticColors.successIcon,
                borderRadius: props.reverse ? (thirdOption ? '0' : '0 4px 4px 0') : '4px 0 0 4px',
                '&:hover, &:selected,&.is-checked': {
                    backgroundColor: SharedColors.yellowGreen10,
                    borderColor: theme.semanticColors.successIcon,
                    color: theme.palette.white
                },
                '&.is-disabled.is-checked, &.is-disabled:hover': {
                    opacity: 0.5,
                    '.ms-Icon': {
                        color: 'rgba(255, 255, 255, 0.7)',


                    }
                }
            }
        ],
        invalid: [
            {
                minWidth: 36,
                width: 36,
                padding: '0 10px',
                borderLeft: props.reverse ? '' : 'none',
                borderColor: NeutralColors.gray60,
                color: theme.semanticColors.errorIcon,
                borderRadius: props.reverse ? '4px 0 0 4px' : thirdOption ? '0' : '0 4px 4px 0',
                '&:hover, &:selected, &.is-checked': {
                    backgroundColor: theme.palette.red,
                    borderColor: theme.semanticColors.errorIcon,
                    color: theme.palette.white
                },
                '&.is-disabled.is-checked, &.is-disabled:hover': {
                    opacity: 0.5,
                    '.ms-Icon': {
                        color: 'rgba(255, 255, 255, 0.7)'
                    }
                }
            }
        ],
        third: [
            {
                ...(() => {
                    if (!thirdOption) {
                        return {}
                    }
                    return {
                        minWidth: 36,
                        width: 36,
                        padding: '0 10px',
                        borderLeft: 'none',
                        borderColor: NeutralColors.gray60,
                        color: thirdOption.color,
                        borderRadius: '0 4px 4px 0',
                        '&:hover, &:selected, &.is-checked': {
                            backgroundColor: thirdOption.color,
                            borderColor: thirdOption.color,
                            color: theme.palette.white
                        },
                        '&.is-disabled.is-checked, &.is-disabled:hover': {
                            opacity: 0.5,
                            '.ms-Icon': {
                                color: 'rgba(255, 255, 255, 0.7)'
                            }
                        }
                    }
                })()
            }
        ]
    });

    const updateSectionAnswerPositivity = useCallback((answerText: string | null) => {
        const {answers} = procedureContext.answers ? procedureContext : reportContext;

        if (!answers) {
            return;
        }

        const _isValueAnswer = (answerText: string | null, value: 'positive' | 'negative') => {
            const _callback = value === 'positive'
                ? (a: string) => isPositiveAnswer(a, props.reverse ? answerOptions?.invalid : answerOptions?.valid)
                : (a: string) => isNegativeAnswer(a, props.reverse ? answerOptions?.valid : answerOptions?.invalid);

            return answerText && _callback(answerText);
        }

        // if there are no manual answers, we should check if there are auto answers
        const autoAnswers = (answers as any[]).filter(a => a.autoIndexes && a.autoIndexes.length > 0);
        // if there are auto answers, set section positivity value based on auto answers value:
        // negative - if at least one negative auto answer
        // positive - if there are not negative auto answers
        const containsAutoNegative = !!autoAnswers.length && autoAnswers
            .some(aa => aa.autoIndexes
                .map((aai: any) => _isValueAnswer(aa['answerText' + aai], 'negative'))
                .some((aan: boolean) => aan));
        const containsAutoPositive = !!autoAnswers.length && autoAnswers
            .some(aa => aa.autoIndexes
                .map((aai: any) => _isValueAnswer(aa['answerText' + aai], 'positive'))
                .some((aan: boolean) => aan));

        const containsNegative = answers.some(x => _isValueAnswer(x.answerText, 'negative')) || containsAutoNegative;
        const containsPositive = answers.some(x => _isValueAnswer(x.answerText, 'positive')) || containsAutoPositive;

        if (!containsNegative && !containsPositive && isPositiveSectionAnswer != null) {
            updateSectionAnswer(null);
            return;
        }

        if (containsNegative && isPositiveSectionAnswer !== false) {
            updateSectionAnswer(false);
            return;
        }

        if (!containsNegative && containsPositive && !isPositiveSectionAnswer) {
            updateSectionAnswer(true);
        }
    }, [isPositiveSectionAnswer])

    const updateSectionAnswers = (answerText: string | null) => {
        const {answers, updateAnswers} = procedureContext.answers ? procedureContext : reportContext;

        if (!updateAnswers || !answers || answers.length == 0 || !props.itemId) {
            return;
        }

        const updatedAnswers = [...answers];
        const index = answers.findIndex((value) => value.id == props.itemId);

        if (index == -1) {
            return;
        }

        updatedAnswers[index].answerText = answerText;
        updateAnswers(updatedAnswers as any);
    }

    const {update, isUpdating} = useUpdateItemAnswer();

    const [validSelected, setValidAnswer] = useState<boolean>(false);
    const [invalidSelected, setInvalidAnswer] = useState<boolean>(false);
    const [thirdSelected, setThirdAnswer] = useState<boolean>(false);

    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        console.debug("[ANSWER::WORK_DONE::DISABLE]", isTabEnabled, isAnswerOptionsLoading, isUpdating, !!props.disabled);
        setDisabled(!isTabEnabled || isAnswerOptionsLoading || isUpdating || !!props.disabled)
    }, [isAnswerOptionsLoading, isUpdating, props.disabled, isTabEnabled]);

    const onYes = () => {
        let newState = !validSelected;
        let answerText = newState ? 'Yes' : null;

        if (props.onChange) props.onChange(answerText);
        onUpdate(answerText);

        setInvalidAnswer(false);
        setValidAnswer(newState);
        setThirdAnswer(false);
    };
    const onNo = () => {
        let newState = !invalidSelected;
        let answerText = newState ? 'No' : null;

        if (props.onChange) props.onChange(answerText);
        onUpdate(answerText);

        setValidAnswer(false);
        setInvalidAnswer(newState);
        setThirdAnswer(false);
    };
    const onThird = () => {
        let newState = !thirdSelected;
        let answerText = newState ? thirdOption!.answerOptionOnSet : null;

        if (props.onChange) props.onChange(answerText);
        onUpdate(answerText);

        setValidAnswer(false);
        setInvalidAnswer(false);
        setThirdAnswer(newState);
    };

    const onUpdate = (answerText: string | null) => {
        if (props.ignoreAnswerUpdating) {
            updateSectionAnswers(answerText);
            if (props.onUpdate) {
                props.onUpdate(answerText);
            }
            if (props.ignoreSectionAnswerUpdating !== true) {
                updateSectionAnswerPositivity(answerText);
            }

            return;
        }

        update({
            jobId: jobId,
            tableType: props.tableType,
            itemId: props.itemId,
            text: answerText,
            columnInfo: props.columnInfo
        }, {
            onSuccess: () => {
                updateSectionAnswers(answerText);
                if (props.onUpdate) {
                    props.onUpdate(answerText);
                }
                if (props.ignoreSectionAnswerUpdating !== true) {
                    updateSectionAnswerPositivity(answerText);
                }
            }
        });
    }

    useEffect(() => {
        if (!answerOptions) {
            return;
        }

        setValidAnswer(answerOptions?.valid.some(x => x.toLowerCase() == props.value?.toLowerCase()));
        setInvalidAnswer(answerOptions?.invalid.some(x => x.toLowerCase() == props.value?.toLowerCase()));
        setThirdAnswer((thirdOption?.answerOptions && thirdOption.answerOptions.some(x => x.toLowerCase() == props.value?.toLowerCase())) ?? false);
    }, [answerOptions, props.value]);

    useImperativeHandle(ref, () => ({
        reset() {
            if (validSelected) {
                onYes();
                return;
            }

            if (invalidSelected) {
                onNo();
                return;
            }

            if (thirdOption && thirdSelected) {
                onThird();
                return;
            }
        },
        getCurrentValue() {
            return validSelected || invalidSelected || (thirdSelected && thirdSelected);
        }
    }));

    return (
        <Stack horizontalAlign='center' horizontal grow>
            <DefaultButton toggle
                           onClick={onYes}
                           disabled={disabled || props.disabledPartialy === 'valid'}
                           checked={validSelected}
                           className={props.reverse ? classNames.invalid : classNames.valid}
                           text={props.auto ? 'A' : iconTypes != 'icons' ? 'Y' : undefined}
                           iconProps={{iconName: iconTypes == 'icons' && !props.auto ? 'Accept' : ''}}/>
            <DefaultButton toggle
                           disabled={disabled || props.disabledPartialy === 'invalid'}
                           onClick={onNo}
                           checked={invalidSelected}
                           className={props.reverse ? classNames.valid : classNames.invalid}
                           text={props.auto ? 'A' : iconTypes != 'icons' ? 'N' : undefined}
                           iconProps={{iconName: iconTypes == 'icons' && !props.auto ? 'Cancel' : ''}}/>
            {thirdOption && (
                <DefaultButton toggle
                               disabled={disabled || props.disabledPartialy === 'third'}
                               onClick={onThird}
                               checked={thirdSelected}
                               className={classNames.third}
                               text={!thirdOption.icon ? thirdOption.label : undefined}
                               iconProps={{iconName: thirdOption.icon ?? ''}}/>
            )}
        </Stack>
    );
});

